<template>
    <div align="center">
        <div class="text-h4">Registro dei movimenti</div>
        <br>
        Preventivo N. {{formPreventivo.preventivo.id}}
        <br>
        <hr>

        <div class="row">

            <q-timeline
                layout="comfortable"
                side="right"
                color="secondary"
                class="col-md-6 offset-md-3"
            >
                <q-timeline-entry
                    :subtitle="movimento.dataMovimento"
                    v-for="(movimento,index) in registro_movimenti.movimenti" v-bind:key="index"
                >
                    <q-list bordered >
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{movimento.personaOperativa}}</q-item-label>
                                <q-item-label >{{movimento.descrizione}}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-timeline-entry>
            </q-timeline>

        </div>

        <br><br><hr>
        <div class="row justify-center">
            <div class="col-md-4" align="center">
                <QQButton label="INDIETRO" color="blue-grey" icon="undo" size="md"
                    @click.native="onClickIndietro()"
                />
            </div>
        </div>
        <br><br><br><br><br><br>
    </div>

</template>

<script>
    import { mapState } from "vuex";
    import QQButton from "@/components/QQButton.vue";

    export default {
        name: "RegistroMovimenti",
        data() {
            return {

            }
        },
        components: {
            QQButton
        },
        computed: {
            ...mapState({
                area: state => state.area,
                titolo_area: state => state.titolo_area,
                formPreventivo: state => state.formPreventivo,
                registro_movimenti: state => state.registro_movimenti
            }),

        },
        methods: {

            onClickIndietro() {
                this.$router.push({name : "Preventivi.MenuGestionePreventivi"});
            },

        }
    }
</script>

<style>
    .table {
        border-collapse: collapse;
        width: 100%;
    }

    .table td, .table th {
        padding: 8px;
        margin-bottom: 6px;
        border: 1px SOLID #C0C0C0;
    }

    .table th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        color: white;
        background: -moz-linear-gradient(top, rgba(30,87,153,1) 0%, rgba(117,177,226,0.7) 92%, rgba(125,185,232,0) 100%);
        background: -webkit-linear-gradient(top, rgba(30,87,153,1) 0%,rgba(117,177,226,0.7) 92%,rgba(125,185,232,0) 100%);
        background: linear-gradient(to bottom, rgba(30,87,153,1) 0%,rgba(117,177,226,0.7) 92%,rgba(125,185,232,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#007db9e8',GradientType=0 );
    }
</style>
